<template>
  <SectionWithMaxWidth data-testid="vonage-home-page-blog-section" class="blog-section-background">
    <div class="py-6 color-neutral-700">
      <div class="my-6 text-heading-2">{{ blok.title }}</div>
      <RichText class="m-0 color-neutral-600 font-light" :text="blok.description" />
      <div class="flex-col">
        <div class="flex md:hidden lg:flex flex-col h-full md:h-[550px] md:flex-row gap-4 py-4">
          <div class="md:basis-[50%]">
            <VonageHomePageBlogCard :article="articleInEvidence" highlight />
          </div>
          <div class="md:basis-[50%]">
            <div class="grid h-full md:grid-cols-2 gap-4">
              <VonageHomePageBlogCard
                v-for="article in articles.slice(1)"
                :key="article.url"
                :article="article"
                :landing-page="true"
              />
            </div>
          </div>
        </div>

        <div class="hidden md:flex lg:hidden flex-col h-full md:flex-row gap-4 py-4">
          <div class="basis-[100%]">
            <div class="grid h-full md:grid-cols-2 gap-4">
              <BlogArticleCard v-for="article in articles.slice(0, -1)" :key="article.url" :article="article" />
            </div>
          </div>
        </div>
      </div>
      <NuxtLink :href="`/${i18n.locale.value}/blog`" type="button">
        <VButton label="View All Blog Posts" icon="arrow-right-line" icon-trailing appearance="ghost"></VButton>
      </NuxtLink>
    </div>
  </SectionWithMaxWidth>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { VonageHomePageBlogSection } from '@/types/storyblok';
import { VButton } from '@vonage/vivid-vue';
import { useI18n } from 'vue-i18n';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import RichText from '@/components/rich-text/RichText.vue';
import { useArticlesApi } from '@/utils/blog/articles';
import BlogArticleCard from '@/components/blog/BlogArticleCard.vue';
import VonageHomePageBlogCard from '@/components/home/vonage/blog/VonageHomePageBlogCard.vue';

const i18n = useI18n();

defineProps({
  blok: { type: Object as PropType<VonageHomePageBlogSection>, required: true },
});

const { articles } = await useArticlesApi({ pageSize: 5 });

const articleInEvidence = computed(() => articles.value[0]);
</script>

<style scoped lang="scss">
.blog-section-background {
  background-color: var(--vvd-color-cta-50);
}
</style>
